body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Inter', 'fieldwork', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
}

.Toastify__toast-theme--light {
  border-radius: 8px;
  color: #333;
  font-family: 'Fieldwork';
  font-size: 17px;
}

.Toastify__toast--error {
  border: 1px solid #e0b3b2;
  background-color: #fff4f4;
}

.Toastify__toast--success {
  border: 1px solid rgb(118, 194, 92);
  background-color: rgb(239, 248, 236);
}

.Toastify__toast-theme--light.Toastify__toast--error
  > .Toastify__toast-body
  > .Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter
  > svg {
  fill: #d72c0d;
}

.Toastify__toast-theme--light.Toastify__toast--success
  > .Toastify__toast-body
  > .Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter
  > svg {
  fill: #67bc4a;
}

iframe {
  border: none;
}
